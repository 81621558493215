import React, { useState } from 'react'
import OTPInput, { ResendOTP } from "otp-input-react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputLabel } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { verifyOtpMail } from '../API';

function OTPVerification() {
    const toastmsg=(msg)=>toast(msg)  
    const [otp, setOtp] = useState(''); 
    const token = localStorage.getItem('token')
    const navigate=useNavigate()
    const emailId = useLocation()
    const handleverifyOtp=()=>{
        verifyOtpMail(emailId.state.email,otp, token).then((res)=>{
            toastmsg(res.data.message)
            if(res.status===200 || res.status==="200"){
                setTimeout(()=>{
                    navigate('/changepassword',{state:{email:emailId.state.email}})
                },2000)
             
            }
        })
        .catch((err)=>{
            toastmsg(err.data.message)
        })
   
    }
  return (
    <>
    <div className='LoginPage-Main-wrapper'>
        {console.log('emailId',emailId)}
    <img src="/assets/images/Ottonomy Logo_White_With_TM-02.png" className='Login-page-form-logo-image'/>
      
      <div className='Loginpage-main-form'>  
          <div className="Login_page_input_wholeLabel_wrapper"> 
             <InputLabel style={{textAlign:'left',color:'#979FB8',paddingLeft:'25px',marginBottom:'8px',fontSize: '17px'}}>  Enter OTP</InputLabel>
             <div className="Loginpage-main-form-inner-div_otp">
             <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false}  className="Cart_page_otp_input" /> 
            </div>
        </div> 
    </div>
    <div className='Loginpage-btn-wrapper'>
           <button onClick={handleverifyOtp} className='Loginpage-btn-wrapper-btn'>Verify OTP</button>
        </div>

</div>  
<ToastContainer/>
</>
  )
}

export default OTPVerification
