import React, { useRef, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  KmlLayer,
} from "react-google-maps";
import { useJsApiLoader } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import MarkerInfowindow from "./MarkerInfowindow";

let kmlurl = localStorage.getItem("kmlurl");

const GoogleTwo = withScriptjs(
  withGoogleMap((props) => {
    const emailId = localStorage.getItem("useremail");
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { fleetList, markers } = useSelector(state => state.fleetList)

    if (!emailId) {
      navigate('/')
    }

    const bounds = new window.google.maps.LatLngBounds();

    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M",
    });

    const mapRef = useRef(null);

    useEffect(() => {
      if (fleetList.length === 0) {
        dispatch(fleetListAndRobotStatusByUserEmail(emailId))
      }

      if (markers && mapRef.current) {
        console.log("MARKERS", markers)
        console.log("MARKERS", fleetList)
        markers.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(
              marker.position.lat,
              marker.position.lng
            )
          );
        });
        mapRef.current.fitBounds(bounds);
      }
    }, [isLoaded, markers, bounds, fleetList, dispatch, emailId]);

    return (
      isLoaded && fleetList.length !== 0 &&
      <GoogleMap
        ref={mapRef}
        defaultZoom={10}
        defaultCenter={bounds.getCenter()}
      >
        <KmlLayer url={kmlurl} />
        {
          props.mapchecktwo ? (
            <>
              {fleetList && fleetList?.map((item) => (
                item.robots.map((robot, index) => (
                  <MarkerInfowindow
                    key={"markerInfo__" + index}
                    latitde={parseFloat(robot.latitude)}
                    logitde={parseFloat(robot.longitude)}
                    itm={robot}
                  />
                ))
              ))}
            </>
          ) : (
            <>
              {fleetList && fleetList?.map((item, index) => (
                <MarkerInfowindow
                  key={"markerInfo__" + index}
                  latitde={parseFloat(item.latitude)}
                  logitde={parseFloat(item.longitude)}
                  itm={item} />
              ))}
            </>
          )
        }
      </GoogleMap >
    );
  }));

export default GoogleTwo;
