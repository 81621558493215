import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import DashboardSideBar from './DashboardSideBar'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { addfleet, addUser } from '../API';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import DashboardBottomBar from '../components/DashboardBottomBar';
function AddUser(props) {

    const addusererror=(msg)=> toast(msg)
    const addusersuccess=(msg)=>toast(msg)
    const navigate =useNavigate()
    const isopen =useSelector(state=>state.dashboardopenReducer) 
    const token =localStorage.getItem('token')
    const validationSchema = yup.object({
         emailId:yup.string().required('User Name is required'),
         password: yup.string().min(6,"Password should be minimum 6 letters").required('Password is required'),
         firstName:yup.string().required('First Name is required'),
         lastName:yup.string().required('Last Name is required'),
         contactNumber:yup.number().required('Contact Number is required')
     });
    const formik =useFormik({
        initialValues: {
          emailId:'',
          password:'',
          firstName:'',
          lastName:'',
          contactNumber:''       },
        validationSchema:validationSchema,
        onSubmit: (values) => {
            addUser(values, token)
            .then((res)=>{
                   addusersuccess(res.data.message)        
                setTimeout(()=>{
                    navigate('/userlist')
                },2000)    
            })
            .catch((err)=>{
                addusererror(err.data.message)
            })
        }    
     })
    return (
    <>
    <Header/>
  <div className="Fleet_page_Whole_content_wrapper_main">
 
    <DashboardSideBar dashboardopen={props.dashboardopen} active="userlist"/>
    <DashboardBottomBar active="userlist" />
     <div className={isopen.userlogindata  ?'Dashboard_page_right_side_fleetpage margin_left_close':'Dashboard_page_right_side_fleetpage margin_left' }>
       <h4 className="Dashboard_page_Robot_Card_heading">   Add New User </h4>
       <div className='AddFleet_page_content'> 
          <form onSubmit={formik.handleSubmit} >
            <div className="AddFleet_form_field_wrapper"> 
               <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.emailId}
                    name="emailId"
                    onChange={formik.handleChange}
                    placeholder='Email'
                    error={
                        formik.touched.emailId &&
                        Boolean(formik.errors.emailId)
                    }
                    helperText={
                        formik.touched.emailId && formik.errors.emailId
                    }
                   style={{margin:'15px'}}
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.password}
                    name="password"
                    onChange={formik.handleChange}
                    placeholder='Password'
                    error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                    }
                    helperText={
                        formik.touched.password && formik.errors.password
                    }
                    style={{margin:'15px'}}
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.firstName}
                    name="firstName"
                    onChange={formik.handleChange}
                    placeholder='First Name'
                    error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                    }
                    helperText={
                        formik.touched.firstName && formik.errors.firstName
                    }
                    style={{margin:'15px'}}
                    />
                <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.lastName}
                    name="lastName"
                    onChange={formik.handleChange}
                    placeholder='Last Name'
                    error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                    }
                    helperText={
                        formik.touched.lastName && formik.errors.lastName
                    }
                    style={{margin:'15px'}}
                    />      
            </div>
            <div className="AddFleet_form_field_wrapper"> 
             <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                    //     </InputAdornment>
                    //   ),
                    }}
                    value={formik.values.contactNumber}
                    name="contactNumber"
                    onChange={formik.handleChange}
                    placeholder='Contact Number'
                    error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                        formik.touched.contactNumber && formik.errors.contactNumber
                    }
                    style={{margin:'15px'}}
                    />
            </div>
            <div className="AddFleet_page_save_button_wrapper"> 
            <button type="submit"> Save</button>
            </div>
        </form>   
       </div>
     </div>
     </div>
     <ToastContainer/> 
    </>
       )
}

export default AddUser
